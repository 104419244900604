import React, { useEffect, useState } from "react";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import API from "../../services/API";
import { connect } from "react-redux";
import { Backdrop, CircularProgress } from "@material-ui/core";
import MUIDataTable, { TableFilterList } from "mui-datatables";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Chip from "@material-ui/core/Chip";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Badge,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import { debounceSearchRender } from "../DebounceSearchRender";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import ServiceFormModal from "./Modals/ServiceFormModal";

import { useHistory } from "react-router-dom";
import { vehicle } from "faker/locale/zh_TW";

// ----------STYLING------------

const CustomChip = ({ label, data, onDelete }) => {
  return (
    <Box pb={1} pt={1}>
      <Chip
        variant="outlined"
        // color="secondary"
        label={label}
        onDelete={onDelete}
      />
    </Box>
  );
};

const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

const stripedTableStyle = (theme) => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "theme.palette.background.default",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  card1: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  learnMoreBtn: {
    marginLeft: "8px",
  },
}));

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  card1: {
    height: "70vh",
    backgroundColor: "#BFFCC6",
  },
});

const ServiceRecords = ({
  apiEnd,
  serviceRecordActive,
  serviceRecordTableState,
  dispatch,
  vehicleSelected,
}) => {
  const [vehicleHistory, setVehicleHistory] = useState(null);
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [recordCount, setRecordCount] = useState(0);
  const [vehicleClicked, setVehicleClicked] = useState(null);
  const [refreshPage, setRefreshPage] = useState(false);
  const [currentDataSet, setCurrentDataSet] = useState(null);
  const [previousDataSet, setPreviousDataSet] = useState(null);
  const [nextDataSet, setNextDataSet] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicleList, setVehicleList] = useState(null); // List of active vehicle IDs
  const [serviceTypeList, setServiceTypeList] = useState(null); // List of available service types
  const [editModelOpen, setEditModelOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [newRecord, setNewRecord] = useState(false);
  // TEST
  // const [expanded, setExpanded] = useState(false);
  // /TEST
  const [activeColumns, setActiveColumns] = useState({
    vehicle_ID: true,
    date_completed: true,
    odometer: true,
    record_type: true,
    service_type: true,
    service_brakes: true,
    service_jobno: true,
    tyre_type: false,
    tyre_size: false,
    tyre_pos: false,
    description_tag: true,
  });

  const classes = useStyles();
  // const history = useHistory();
  const bull = <span className={classes.bullet}>•</span>;
  const overalltheme = useTheme();

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIPaper: {
          variant: "outlined",
        },
        MuiTableHead: {
          root: { backgroundColor: "#666" },
          // FIX: Increased Z-index of the header as the white line was caused by column seperator of he body going over the head.
          style: { zIndex: "100" },
        },
        MUIDataTableHeadCell: {
          fixedHeader: { backgroundColor: "#666", color: "#FFF" },
          toolButton: { color: "#FFF" },
        },
        MUIDataTableSelectCell: {
          headerCell: {
            backgroundColor: "#666",
            color: "#fff",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      },
    });

  // On first load, clear filters
  // FIXME: Vehicle filter needs to persist when navigating externally
  useEffect(() => {
    if (!vehicleSelected && !serviceRecordTableState.vehicleFilterIds[0]) {
      dispatch({ type: "CLEAR_SERVICE_RECORD_TABLE_STATE" });
      refreshPageFunction();
    }

    // if (overalltheme.breakpoints.up("sm")) {
    //   setExpanded(true);
    // } else {
    //   setExpanded(false);
    // }

    // if (checkFiltered()) {
    //   dispatch({ type: "CLEAR_SERVICE_RECORD_TABLE_STATE" });
    //   refreshPageFunction();
    // }
  }, []);

  // When page is loaded, go and fetch the first chunk of data
  // Implement load data for correct VID
  useEffect(() => {
    async function getVecHistory() {
      const url =
        process.env.REACT_APP_API_URL +
        "/VehicleTechnicalHistory/" +
        vehicleSelected +
        "/";
      const SRData = await API.getAPIData(url, "GET", null);
      if (SRData.status === 200) {
        let responseObjectTemp = await SRData.json();
        // console.log(responseObjectTemp);
        setVehicleHistory(responseObjectTemp);
      }
    }

    async function getVehiclesList() {
      const url =
        process.env.REACT_APP_API_URL +
        "/ListVehicleNumbers/" +
        (serviceRecordActive ? "active/" : "archived");
      const SRData = await API.getAPIData(url, "GET", null);
      if (SRData.status === 200) {
        let responseObjectTemp = await SRData.json();
        setVehicleList(responseObjectTemp);
      }
    }

    async function getServiceTypeList() {
      const url = process.env.REACT_APP_API_URL + "/ListServiceRecordsTypes/";
      const SRData = await API.getAPIData(url, "GET", null);
      if (SRData.status === 200) {
        let responseObjectTemp = await SRData.json();
        setServiceTypeList(responseObjectTemp);
      }
    }
    async function getVehicleInfo() {
      var slug = "/Vehicles/";

      if (!serviceRecordActive) {
        slug = "/ArchivedVehicles/";
      }
      var url =
        process.env.REACT_APP_API_URL +
        slug +
        // (!serviceRecordActive ? "/Vehicles/" : "/ArchivedVehicles/") +
        vehicleSelected +
        "/";

      const SRData = await API.getAPIData(url, "GET", null);
      if (SRData.status === 200) {
        let responseObjectTemp = await SRData.json();
        setVehicleInfo(responseObjectTemp);
      }
    }

    // TODO: Implement better usage of the functions and flow to avoid unneccesary API calls

    // Get list of service types
    getServiceTypeList().then(() => {});

    // When page is loaded, go and fetch the vehicle id list
    getVehiclesList().then(() => {});

    getVehicleInfo().then(() => {});

    getVecHistory().then(() => {});

    let filteredVehicle = serviceRecordTableState.vehicleFilterIds[0];
    if (vehicleSelected) filteredVehicle = vehicleSelected;
    updateDataSets(
      filteredVehicle,
      serviceRecordTableState.recordTypeFilter[0],
      serviceRecordTableState.rowsPerPage,
      serviceRecordTableState.rowsPerPage * serviceRecordTableState.page,
      serviceRecordTableState.rowsPerPage * serviceRecordTableState.page, // Set offset same as previousOffset to populate current data set
      searchText,
      true // Reset data, i.e. load currentDataSet from API
    );

    // When page is loaded, check if a filter is applied
    checkFiltered();
  }, [refreshPage]);

  async function updateRecordsVehicleSelect(
    vehicleID,
    serviceType,
    limit,
    offset,
    searchText
  ) {
    if (offset < 0) {
      return null;
    }

    // If the required differs from the current, fetch the correct dataset
    // setIsLoading(true);
    // setIsFiltered(true);
    const tempUrl =
      process.env.REACT_APP_API_URL +
      "/" +
      apiEnd +
      "?limit=" +
      limit.toString() +
      "&offset=" +
      offset.toString() +
      // "&vehicle_ID=023";
      (vehicleID ? "&vehicle_ID=" + vehicleID : "") +
      (serviceType ? "&record_type=" + serviceType : "") +
      (searchText ? "&search=" + searchText : "");
    const SRData = await API.getAPIData(tempUrl, "GET", null);
    let responseObjectTemp = await SRData.json();
    setRecordCount(responseObjectTemp.count);
    // setGenericObject(responseObjectTemp.results); // Can either be current, previous or next
    // setIsLoading(false);
    return responseObjectTemp.results;
  }

  // useEffect(() => {
  //   setIsLoading(false);
  // }, [currentDataSet]);

  function updateDataSets(
    vehicleId,
    serviceType,
    limit,
    previousOffset,
    offset,
    searchText,
    resetData
  ) {
    if (resetData) {
      // Get current
      setIsLoading(true);
      updateRecordsVehicleSelect(
        vehicleId,
        serviceType,
        limit,
        offset,
        searchText
      ).then((value) => {
        setCurrentDataSet(value);
        setIsLoading(false);
      });
    } else if (previousOffset < offset) {
      setCurrentDataSet(nextDataSet);
    } else if (previousOffset > offset) {
      setCurrentDataSet(previousDataSet);
    }

    // Get previous
    updateRecordsVehicleSelect(
      vehicleId,
      serviceType,
      limit,
      offset - limit,
      searchText
    ).then((value) => {
      setPreviousDataSet(value);
    });

    // Get next
    updateRecordsVehicleSelect(
      vehicleId,
      serviceType,
      limit,
      limit + offset,
      searchText
    ).then((value) => {
      setNextDataSet(value);
    });
  }

  // This useEffect is used to customize the displayed columns based on service record type
  useEffect(() => {
    if (serviceRecordTableState.recordTypeFilter[0]) {
      switch (serviceRecordTableState.recordTypeFilter[0].toLowerCase()) {
        case "maintenance":
          setActiveColumns({
            ...activeColumns,
            service_brakes: false,
            service_jobno: false,
            description_tag: true,
            service_type: false,
            tyre_type: false,
            tyre_size: false,
            tyre_pos: false,
          });
          break;
        case "service":
          setActiveColumns({
            ...activeColumns,
            service_brakes: true,
            service_type: true,
            service_jobno: true,
            description_tag: true,
            tyre_type: false,
            tyre_size: false,
            tyre_pos: false,
          });
          break;
        case "brakes":
          setActiveColumns({
            ...activeColumns,
            service_brakes: false,
            service_type: false,
            service_jobno: false,
            description_tag: true,
            tyre_type: false,
            tyre_size: false,
            tyre_pos: false,
          });
          break;
        case "tyre":
          setActiveColumns({
            ...activeColumns,
            service_brakes: false,
            service_jobno: false,
            service_type: false,
            description_tag: true,
            tyre_type: true,
            tyre_size: true,
            tyre_pos: true,
          });
          break;
        default:
          setActiveColumns({
            ...activeColumns,
            service_brakes: true,
            service_jobno: true,
            description_tag: true,
            service_type: true,
            tyre_type: true,
            tyre_size: true,
            tyre_pos: true,
          });
      }
    }
  }, [serviceRecordTableState.recordTypeFilter]);

  const emptyRecord = {
    url: process.env.REACT_APP_API_URL + "/ServiceRecords/",
    vehicle_ID:
      isFiltered && currentDataSet && currentDataSet.length > 0
        ? currentDataSet[0].vehicle_ID
        : "",
    chassis_no:
      isFiltered && currentDataSet && currentDataSet.length > 0
        ? currentDataSet[0].chassis_no
        : "",
    odometer: 0,
    service_jobno: "",
    service_brakes: 0,
    service_type: "",
    tyre_type: "",
    tyre_size: "",
    tyre_pos: "",
    description_tag: "",
    job_data: [
      {
        hotkey: "General",
        description: "",
        remarks: "",
      },
    ],
    date_added: new Date().toISOString(),
    date_updated: new Date().toISOString(),
    date_completed: new Date().toISOString().substring(0, 10),
    remarks: "None",
    record_type: "",
  };

  async function deleteRecord(record) {
    await API.getAPIData(record.url, "DELETE", record);
  }

  function downloadXLSX() {
    setIsLoading(true);
    const vehicleID = serviceRecordTableState.vehicleFilterIds[0];
    const serviceType = serviceRecordTableState.recordTypeFilter[0];

    const tempUrl =
      process.env.REACT_APP_API_URL +
      "/" +
      apiEnd +
      "?format=xlsx" +
      (vehicleID ? "&vehicle_ID=" + vehicleID : "") +
      (serviceType ? "&record_type=" + serviceType : "") +
      (searchText ? "&search=" + searchText : "");

    API.getAPIDataXLSX(tempUrl, "GET", null, "xlsx", setIsLoading);
    return false;
  }
  var tableTitle = "";
  if (serviceRecordActive) {
    tableTitle = " - Active";
  } else {
    tableTitle = " - Archived";
  }

  const options = {
    denseTable: true,
    // jumpToPage: true,
    textLabels: {
      toolbar: {
        downloadCsv: "Download XLSX",
      },
    },
    fixedHeader: true,
    filterType: "checkbox",
    fixedSelectColumn: false,
    responsive: "standard",
    tableBodyHeight: "70vh",
    // tableBodyMaxHeight: "70vh ",
    onRowClick: (rowData, rowMeta) => {
      handleCellClick(rowMeta.dataIndex);
    },
    onRowsDelete: (rowsDeleted) => {
      rowsDeleted.data.forEach((value) =>
        deleteRecord(currentDataSet[value.dataIndex])
      );
    },
    rowsPerPageOptions: [5, 10, 25, 50],
    rowsPerPage: serviceRecordTableState.rowsPerPage,
    count: recordCount,
    serverSide: true,
    page: serviceRecordTableState.page,
    print: false,
    download: true,
    onDownload: downloadXLSX,
    search: true,
    customSearchRender: debounceSearchRender(500),
    // tableBodyHeight: '600px',
    // onSearchChange: (searchTextOnChange) => {
    //   setSearchText(searchTextOnChange);
    // },
    // searchText: searchText,
    onTableChange: (action, tableState) => {
      if (
        action === "filterChange" ||
        action === "changeRowsPerPage" ||
        action === "resetFilters" ||
        action === "changePage" ||
        action === "search"
      ) {
        setPreviousDataSet(nextDataSet);

        const page = action === "changePage" ? tableState.page : 0;
        const previousOffset =
          serviceRecordTableState.rowsPerPage * serviceRecordTableState.page;
        const newOffset = page * tableState.rowsPerPage;

        // For all actions other than page changes, the current data should reset (e.g. when a filter was changed or
        // size of a page was changed, load new current data because we can't use previous or next)
        // On the other hand, if a "jump to page" (changePage) was actioned, the previous or next data mostly likely can't be used
        // because the jump would be larger than a page. Therefore, resetData should be true if the jump is larger than a page size
        const resetData =
          action !== "changePage" ||
          (action === "changePage" &&
            Math.abs(newOffset - previousOffset) > tableState.rowsPerPage);

        // setIsLoading(true);
        updateDataSets(
          tableState.filterList[0],
          tableState.filterList[3],
          tableState.rowsPerPage,
          previousOffset,
          newOffset,
          tableState.searchText,
          resetData
        );
        setSearchText(tableState.searchText);
        dispatch({
          type: "SERVICE_RECORD_TABLE_STATE",
          value: {
            vehicleFilterIds: tableState.filterList[0],
            page: page,
            rowsPerPage: tableState.rowsPerPage,
            recordTypeFilter: tableState.filterList[3],
          },
        });

        // setRequiredIntentioPage(1);
      } else if (action === "viewColumnsChange") {
        updateColumnsSelected(tableState).then(() => {});
      }
      if (tableState.filterList[0].length > 0) {
        setIsFiltered(true);
      } else {
        setIsFiltered(false);
      }
      // setRefreshPage(!refreshPage);

      // if (action === "filterChange") {
      //   setRefreshPage(true);
      // }
    },
    customToolbar: () => {
      return (
        <Fab
          color="inherit"
          size="small"
          aria-label="add"
          variant="extended"
          // style={
          //   {
          //     //   position: "fixed",
          //     //   bottom: "2rem",
          //     //   right: "2rem",
          //   }
          // }
          onClick={addNew}
        >
          <AddIcon />
        </Fab>
      );
    },
  };

  async function updateColumnsSelected(tableState) {
    const activeColumnsCopy = { ...activeColumns };
    tableState.columns.forEach(
      (value) => (activeColumnsCopy[value.name] = value.display === "true")
    );
    setActiveColumns(activeColumnsCopy);
  }

  const jobDescriptionChipList = (index) => {
    function getTags() {
      return (
        currentDataSet[index].job_data
          // .filter(
          //   (value) => value.hotkey_main === currentDataSet[index].hotkey_main
          // )

          .map((value, index) => {
            function returnFormattedText() {
              if (value.description.length > 2) {
                return (
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <div style={{ fontWeight: 600 }}>
                      {value.description + ": "}
                    </div>
                    &nbsp;
                    {value.remarks}
                  </div>
                );
              } else {
                return (
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    &nbsp;
                    {value.remarks}
                    &nbsp;
                  </div>
                );
              }
            }
            // if (
            //   currentDataSet[index].job_data.description.length > 2 &&
            //   currentDataSet[index].job_data.remarks.length > 2
            // ) {
            return (
              <Chip
                key={index}
                style={{ marginLeft: 10, marginBottom: 5 }}
                // icon={icon}
                label={returnFormattedText()}
                // onDelete={() => handleDelete(value)}
                // onClick={() => handleOnClick(value)}
                // className={classes.chip}
              />
            );
            // } else {
            //   return <div></div>;
            // }
          })
      );
    }

    return <div>{getTags()}</div>;
  };

  const columns = [
    {
      name: "vehicle_ID",
      label: "Vehicle ID",
      options: {
        filter: true,
        sort: false,
        filterType: "dropdown",
        filterList: serviceRecordTableState.vehicleFilterIds,
        display: !isFiltered && activeColumns.vehicle_ID,
        filterOptions: {
          names: vehicleList,
          fullWidth: true,
        },
      },
    },
    {
      name: "date_completed",
      label: "Date Completed",
      options: {
        filter: false,
        sort: false,
        display: activeColumns.date_completed,
      },
    },

    {
      name: "odometer",
      label: "Odometer",
      options: {
        filter: false,
        sort: false,
        display: activeColumns.odometer,
      },
    },
    {
      name: "record_type",
      label: "Record Type",
      options: {
        filter: true,
        sort: false,
        filterType: "dropdown",
        filterList: serviceRecordTableState.recordTypeFilter,
        filterOptions: {
          names: serviceTypeList,
          fullWidth: true,
        },
        display: activeColumns.record_type,
      },
    },
    {
      name: "service_type",
      label: "Service Type",
      options: {
        filter: false,
        sort: false,
        display: activeColumns.service_type,
      },
    },
    {
      name: "service_brakes",
      label: "Service Brakes",
      options: {
        filter: false,
        sort: false,
        display: activeColumns.service_brakes,
      },
    },
    {
      name: "service_jobno",
      label: "Job No",
      options: {
        filter: false,
        sort: false,
        display: activeColumns.service_jobno,
      },
    },
    {
      name: "description_tag",
      label: "Description",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => jobDescriptionChipList(dataIndex),
        // customBodyRenderLite: (dataIndex) => (<div>{dataIndex}</div>),
        sort: false,
        display: activeColumns.description_tag,
      },
    },
    {
      name: "tyre_type",
      label: "Tyre Type",
      options: {
        filter: false,
        sort: false,
        display: activeColumns.tyre_type,
      },
    },
    {
      name: "tyre_size",
      label: "Tyre Size",
      options: {
        filter: false,
        sort: false,
        display: activeColumns.tyre_size,
      },
    },
    {
      name: "tyre_pos",
      label: "Tyre Pos",
      options: {
        filter: false,
        sort: false,
        display: activeColumns.tyre_pos,
      },
    },
  ];

  useEffect(() => {
    if (vehicleClicked) {
      setEditModelOpen(true);
    }
  }, [vehicleClicked]);

  function handleCellClick(index) {
    setVehicleClicked(currentDataSet[index]);
  }

  // TODO: Determine whether checkFiltered works for all cases
  function checkFiltered() {
    if (!serviceRecordTableState.vehicleFilterIds.length) {
      setIsFiltered(false);
    } else {
      setIsFiltered(true);
    }
  }

  function addNew() {
    setNewRecord(true);
    setEditModelOpen(true);
  }

  function refreshPageFunction() {
    setRefreshPage(!refreshPage);
  }

  return (
    <Grid container className={styles.root} spacing={2}>
      <ServiceFormModal
        open={editModelOpen}
        setOpen={setEditModelOpen}
        vehicleClicked={newRecord ? emptyRecord : vehicleClicked}
        setVehicleClickedSend={() => setVehicleClicked(null)}
        setRefreshPageSend={refreshPageFunction}
        newRecord={newRecord}
        setNewRecordSend={() => setNewRecord(false)}
      />
      {isFiltered && vehicleSelected && (
        <>
          {/* <VehicleHistoryModal
            open={modelOpen}
            setOpen={setModelOpen}
            vehicleClicked={serviceRecordTableState.vehicleFilterIds[0]}
          /> */}
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Card
              // variant="outlined"
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                overflow: "scroll",
              }}
              className={classes.card1}
              raised={true}
            >
              <div>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={12}>
                      {/* <Badge badgeContent={4} color="secondary"> */}
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={true}
                      >
                        {serviceRecordTableState.vehicleFilterIds[0]}
                      </Button>
                      {/* </Badge> */}
                    </Grid>
                    {/* Vehicle Details Accordion */}
                    <Grid item xs={12} md={12}>
                      <Accordion defaultExpanded={true} variant="outlined">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{ fontWeight: "bold" }}>
                            Vehicle Details
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={0}>
                            <Grid item xs={12}>
                              <List className={classes.root}>
                                <ListItem>
                                  <ListItemText
                                    primary="Chassis No:"
                                    secondary={
                                      <React.Fragment>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          className={classes.inline}
                                          color="textSecondary"
                                        >
                                          &nbsp;
                                          {vehicleInfo &&
                                            vehicleInfo.chassis_no}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                  <ListItemText
                                    primary="Engine No:"
                                    secondary={
                                      <React.Fragment>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          className={classes.inline}
                                          color="textSecondary"
                                        >
                                          {vehicleInfo && vehicleInfo.engine_no}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                  <ListItemText
                                    primary="Vehicle Model:"
                                    secondary={
                                      <React.Fragment>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          className={classes.inline}
                                          color="textSecondary"
                                        >
                                          {vehicleInfo && vehicleInfo.model}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                  <ListItemText
                                    primary="Date in Service"
                                    secondary={
                                      <React.Fragment>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          className={classes.inline}
                                          color="textSecondary"
                                        >
                                          &nbsp;
                                          {vehicleInfo &&
                                            vehicleInfo.date_inservice}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                </ListItem>
                              </List>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    {/* Technical Details Accordion */}
                    <Grid item xs={12} md={12}>
                      <Accordion defaultExpanded={false} variant="outlined">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography style={{ fontWeight: "bold" }}>
                            Technical Details
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <List className={classes.root}>
                                <ListItem>
                                  <ListItemText
                                    primary="Most Recent Service"
                                    secondary={
                                      <React.Fragment>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          className={classes.inline}
                                          color="textSecondary"
                                          align="left"
                                        >
                                          &nbsp;
                                          {vehicleHistory &&
                                            vehicleHistory.last_service_date}
                                        </Typography>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          className={classes.inline}
                                          color="textSecondary"
                                          align="right"
                                        >
                                          &nbsp;
                                          {bull}
                                          &nbsp;
                                          {vehicleHistory &&
                                            vehicleHistory.last_service_odometer +
                                              " km"}{" "}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                </ListItem>
                                {/* TODO: Do not display items if there are none */}
                                <Divider />
                                {isFiltered &&
                                  vehicleHistory &&
                                  vehicleHistory.last_tyrejob_date && (
                                    <ListItem>
                                      <ListItemText
                                        primary="Most Recent Tyre Job"
                                        secondary={
                                          <React.Fragment>
                                            <Typography
                                              component="span"
                                              variant="body2"
                                              className={classes.inline}
                                              color="textSecondary"
                                              aligin="left"
                                            >
                                              &nbsp;
                                              {vehicleHistory &&
                                                vehicleHistory.last_tyrejob_date}
                                            </Typography>
                                            <Typography
                                              component="span"
                                              variant="body2"
                                              className={classes.inline}
                                              color="textSecondary"
                                              aligin="right"
                                            >
                                              &nbsp;
                                              {bull}
                                              &nbsp;
                                              {vehicleHistory &&
                                                vehicleHistory.last_tyrejob_odometer +
                                                  " km"}{" "}
                                            </Typography>
                                          </React.Fragment>
                                        }
                                      />
                                    </ListItem>
                                  )}
                                <Divider />
                                {isFiltered &&
                                  vehicleHistory &&
                                  vehicleHistory.last_brakejob_date && (
                                    <ListItem>
                                      <ListItemText
                                        primary="Most Recent Brakes"
                                        secondary={
                                          <React.Fragment>
                                            <Typography
                                              component="span"
                                              variant="body2"
                                              className={classes.inline}
                                              color="textSecondary"
                                              aligin="left"
                                            >
                                              &nbsp;
                                              {vehicleHistory &&
                                                vehicleHistory.last_brakejob_date}
                                            </Typography>
                                            <Typography
                                              component="span"
                                              variant="body2"
                                              className={classes.inline}
                                              color="textSecondary"
                                              aligin="right"
                                              style={{ alignContent: "right" }}
                                            >
                                              &nbsp;
                                              {bull}
                                              &nbsp;
                                              {vehicleHistory &&
                                                vehicleHistory.last_brakejob_odometer +
                                                  " km"}{" "}
                                            </Typography>
                                          </React.Fragment>
                                        }
                                      />
                                    </ListItem>
                                  )}
                              </List>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </CardContent>
              </div>
            </Card>
          </Grid>
        </>
      )}

      <Grid
        item
        xs={12}
        sm={12}
        md={isFiltered && vehicleSelected ? 8 : 12}
        lg={isFiltered && vehicleSelected ? 9 : 12}
      >
        <div className={classes.card1}>
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={"Service Records" + tableTitle}
              data={currentDataSet ? currentDataSet : []}
              columns={columns}
              options={options}
              components={{
                TableFilterList: CustomFilterList,
              }}
              sx={{ "flex-shrink": 1 }}
              // style={{ padding: "1px", size: "small" }}
            />
          </MuiThemeProvider>
        </div>
      </Grid>

      <Backdrop
        style={{ color: "#fff", zIndex: 2000 }}
        open={isLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.isSignedIn,
  accessToken: state.accessToken,
  refreshToken: state.refreshToken,
  serviceRecordTableState: state.serviceRecordTableState,
});

export default connect(mapStateToProps)(
  withStyles(stripedTableStyle, { withTheme: true })(ServiceRecords)
);
